.container-jim {
  width: 83vw;
  margin-top: 120px;
  overflow-y: auto;
  display: grid;
  grid-template-columns: 40% 25% 25%;
  grid-template-rows: 2fr 1fr 1fr 1fr;
  gap: 5px 10px;
  grid-auto-flow: row;
  grid-template-areas:
    'jim-foto mexico paris'
    'jim-foto comfort cradle'
    'jim-foto fork neurosis'
    'jim-copy jim-copy jim-copy';
}

.jim-copy {
  grid-area: jim-copy;
}

.jim-foto {
  grid-area: jim-foto;
}

.mexico {
  grid-area: mexico;
}

.paris {
  grid-area: paris;
}

.comfort {
  grid-area: comfort;
}

.cradle {
  grid-area: cradle;
}

.fork {
  grid-area: fork;
}

.neurosis {
  grid-area: neurosis;
}

.booker {
  transition: 0.5s ease;
  position: relative;
  width: 100%;
}

.booker:hover img {
  opacity: 0.5;
}
.booker:hover .middle {
  opacity: 1;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 40px;
  width: 40px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  border-radius: 50%;
  background-color: black;
}

.text {
  color: white;
  font-size: 12px;
  font-family: franklin-gothic-atf, sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 12px;
}

img.jim-img {
  height: 100%;
  object-fit: contain;
  margin-top: 10px;
}
span {
  text-transform: uppercase;
}

p::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
p.grid-copy {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

p.grid-copy {
  margin: 0;
  padding-bottom: 210px;
}

/* @media (max-width: 400px) {
  .jim-container-mobile.minid {
    padding-bottom: 140px;
  }
} */
