#newsletterContainer {
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 37vw;
  margin-right: 7vw;
  margin-top: 100px;
  overflow-y: scroll;
  margin-bottom: 40px;
}

#newsletterContainer p {
  margin-left: 20%;
  font-size: 18px;
  line-height: 1.5;
  text-align: right;
  color: black;
  margin-right: 0;
  margin-bottom: 20px;
}
.forms {
  display: flex;
  flex-direction: column;
  max-width: 900px;
  align-items: flex-end;
  margin-bottom: 30px;
}
.chimped {
  height: fit-content;
}

.newsletterContainer-intro {
  position: relative;
  z-index: 3;
}
.newsletterContainer-intro p {
  margin-top: 30px;
}

form {
  width: 100%;
}
form button {
  background: black;
  color: white;
  text-transform: uppercase;
  padding: 10px;
  border: none;
  border-radius: 5%;
}
form button:hover {
  background: grey;
}

input {
  height: 50px;
  width: 80%;
  max-width: 900px;
  margin-bottom: 30px;
  padding: 10px;
}
input:focus {
  outline: none;
}
input:hover {
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.video {
  width: 80%;
  align-items: flex-end;
  margin-left: 20%;
  position: relative;
  cursor: pointer;
  margin-bottom: 30px;
}
.vid-button {
  position: absolute;
  z-index: 5;
  top: 60%;
  left: 40%;
  color: white;
  font-size: 1rem;
}
.fa-youtube,
.fa-5x {
  font-size: 3em;
}

@media (min-width: 1400px) {
  .video {
    max-width: 485px;
    margin-left: 14%;
  }
}

@media (min-height: 800px) and (max-height: 1280px) {
  #newsletterContainer {
    margin-top: 120px;
  }
  input,
  p {
    margin-bottom: 20px;
  }
}
@media (min-width: 1150px) {
  #newsletterContainer {
    width: 45vw;
    margin-right: 8.5vw;
    margin-top: 100px;
  }
  #newsletterContainer p {
    margin-left: 20%;
  }
}
@media (min-width: 1075px) and (max-width: 1149px) {
  #newsletterContainer {
    width: 45vw;
    margin-right: 8vw;
  }
  #newsletterContainer p {
    margin-left: 15%;
  }
}
@media (min-width: 700px) and (max-width: 1074px) {
  #newsletterContainer {
    width: 55vw;
    margin-right: 10vw;
    margin-top: 100px;
    margin-bottom: 90px;
    position: fixed;
  }
  .newsletterContainer-intro,
  .video {
    margin-right: 5px;
  }
  #newsletterContainer p {
    margin-right: 0px;
  }
}
@media (max-width: 800px) {
  #newsletterContainer {
    margin-top: 130px;
  }
}

@media (min-width: 540px) and (max-width: 699px) {
  #newsletterContainer {
    justify-content: flex-start;
    margin-right: 15vw;
    width: 90vw;
    margin-top: 140px;
  }
  #newsletterContainer p {
    margin-left: 20%;
    font-size: 1.9rem;
    line-height: 1.2;
    overflow: unset;
  }
}

@media (max-width: 539px) {
  input {
    width: 90%;
    margin-bottom: 20px;
  }
  #newsletterContainer {
    width: 100vw;
    padding-bottom: 180px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-bottom: 0;
  }
  #newsletterContainer p {
    margin-left: 10%;
    margin-bottom: 20px;
    line-height: 1.2;
  }

  .video {
    width: 100%;
  }
  .video img {
    width: 90%;
  }
}
@media (max-width: 400px) {
  #newsletterContainer {
    padding-bottom: 130px;
  }
}
