#navbarJimMobile {
  position: fixed;
  flex-direction: column;
  margin-top: 0;
  align-items: flex-end;
  text-align: right;
  height: 130px;
  justify-content: initial;
  background: white;
  width: 100vw;
  padding: 0 7.5vw 0 9vw;
  z-index: 5;
}

h6.menu {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 30px;
}

@media (min-width: 540px) and (max-width: 600px) {
  h6.menu {
    margin-top: 40px;
  }

  #navbarJimMobile {
    padding: 0 15vw;
  }
}
@media (min-width: 601px) and (max-width: 900px) {
  h6.menu {
    margin-top: 40px;
  }

  .mobile-container {
    position: fixed;
  }
}
