#navbarHome,
#navbar,
#navbarMexico {
  position: fixed;
  display: flex;
  width: 70vw;
  height: 120px;
  top: 50px;
  justify-content: space-between;
  z-index: 5;
}

#navbarMobile {
  position: fixed;
  flex-direction: column;
  margin-top: 0;
  align-items: flex-end;
  text-align: right;
  height: 130px;
  background: white;
  width: 70vw;
  z-index: 5;
}

#navbarMexicoMobileInt {
  position: fixed;
  display: flex;
  width: 70vw;
  height: 90px;
  top: 50px;
  justify-content: space-between;
  z-index: 5;
}
.navbarTitle {
  margin-top: 25px;
}
.navbarMenu {
  margin-top: 20px;
  text-align: left;
}
.navbarBuy {
  font-weight: 500;
  font-style: italic;
  letter-spacing: 2px;
  font-size: 15px;
  margin-top: 10px;
}

#navbarSocial {
  position: fixed;
  display: flex;
  width: 13.5vw;
  height: 100vh;
  top: 0;
  justify-content: space-between;
  align-items: center;
  right: 0;
  z-index: 20;
}

.social {
  display: flex;
  flex-direction: column;
}

#navBarToggle {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: grey;
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 30;
}
#navBarToggle a {
  color: #fff;
}
ul.toggleNavBar {
  list-style-type: none;
  padding: 0;
}
.toggleNavBar {
  text-align: center;
  margin-bottom: 75px;
}

/* //////// MEDIA QUERIES //////////// */

@media (min-width: 1400px) {
  #navbarHome,
  #navbar,
  #navbarMexico {
    width: 66vw;
    max-width: 1260px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  #navbarHome {
    padding: 0;
  }
  #navbarSocial {
    width: 11vw;
  }
}

@media (min-width: 900px) and (max-width: 1199px) {
  #navbarSocial {
    width: auto;
    right: 3vw;
  }
  #navbarMexico {
    width: 80vw;
    padding: 0;
  }
  #navbarHome {
    width: 80vw;
  }
  #navbar {
    width: 80vw;
  }
}
@media (min-width: 841px) {
  .navbarBuy {
    margin-top: 3px;
  }
}
@media (min-width: 788px) and (max-width: 840px) {
  #navbarMexicoMobileInt {
    flex-wrap: wrap;
  }
  #navbarMexicoMobileInt::after {
    content: '';
    width: 100%;
  }
  .mobileInt:last-child {
    margin-left: auto;
    order: 1;
  }
}

@media (min-width: 699px) and (max-width: 785px) {
  #navbarMexicoMobileInt {
    flex-wrap: wrap;
  }

  .navbarMexicoMobileInt {
    margin-left: auto;
  }
  .navbarMexicoMobileInt.navbarBuy.mobileInt {
    margin-top: -20px;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .navbarMenu {
    margin-top: 40px;
  }
  h1 {
    font-size: 4.5rem;
  }
  #navbarMobile {
    height: 210px;
  }
  .navbarWorldFood {
    line-height: 0.8;
  }
}

@media (min-width: 540px) and (max-width: 899px) {
  #navbarHome {
    width: 70vw;
  }
  #navbarSocial {
    width: 11vw;
  }
  #navbarMobile.navbarHomeMobileHybrid,
  #navbarMobile {
    width: 70vw;
  }
  #navbarMobile {
    height: 170px;
  }

  .navbarTitle h3 {
    margin-top: 70px;
  }
}

@media (min-width: 540px) and (max-width: 600px) {
  h3 {
    margin-top: 30px;
  }
  h1 {
    font-size: 4.5rem;
  }
}

@media (min-width: 539px) and (max-width: 760px) {
  #navbarMexicoMobile {
    width: 70vw;
  }
}

@media (max-width: 539px) {
  #navbarSocial {
    width: 100vw;
    height: 80px;
    bottom: 0;
    top: unset;
    z-index: 25;
    background: #fff;
    justify-content: center;
  }
  .social {
    flex-direction: row;
  }
  #navbarMobile.navbarHomeMobileHybrid {
    height: 150px;
  }
  .navbarWorldFood h1 {
    font-weight: 400;
    font-size: 5rem;
    line-height: 0.7;
  }
  #navbarMobile {
    width: 83vw;
  }
  #navbarMobile.navbarMexico {
    height: 150px;
  }
}
@media (max-width: 400px) {
  .navbarWorldFood h1 {
    font-size: 4rem;
    line-height: 0.7;
  }
  #navbarMobile.navbarHomeMobileHybrid {
    height: 140px;
  }
}
