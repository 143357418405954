.container.home {
  align-items: center;
}

.homeContainer-main {
  display: grid;
  grid-template-columns: 0.95fr 0.95fr 1.1fr 1.1fr 0.95fr 0.95fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 20px 20px;
  grid-template-areas: 'ltla ltra carousel-wrapper carousel-wrapper rtla copy' 'lmla lmra carousel-wrapper carousel-wrapper rmla copy' 'lbla lbra carousel-wrapper carousel-wrapper rbla copy';
  max-width: 1260px;
}

.ltla {
  grid-area: ltla;
}
.ltra {
  grid-area: ltra;
}
.lmla {
  grid-area: lmla;
}
.lmra {
  grid-area: lmra;
}
.lbla {
  grid-area: lbla;
}
.lbra {
  grid-area: lbra;
}
.rtla {
  grid-area: rtla;
}
.rmla {
  grid-area: rmla;
}
.rbla {
  grid-area: rbla;
}
.copy {
  grid-area: copy;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.carousel-wrapper {
  grid-area: carousel-wrapper;
}

.signUpBtn {
  width: 100%;
  align-items: center;
  background: black;
  color: white;
  font-size: 14px;
  bottom: 0;
  font-weight: 500;
  font-family: franklin-gothic-atf, sans-serif;
  padding: 4px 20px;
  border: none;
  align-self: flex-end;
  text-align: center;
}
.signUpBtn:hover {
  background: grey;
  color: white;
}

.homeContainer-footer {
  display: flex;
  justify-content: space-between;
}
@media (min-width: 1400px) {
  .homeContainer-home {
    width: 66vw;
    max-width: 1260px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .homeContainer-home {
    width: 70vw;
  }
}

@media (min-width: 901px) and (max-width: 1199px) {
  .homeContainer-home {
    width: 80vw;
  }
}
