/**
 * @license
 * MyFonts Webfont Build ID 3994097, 2021-01-06T14:29:25-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: MundoSansPro-Regular by Monotype
 * URL: https://www.myfonts.com/fonts/mti/mundo-sans/pro-regular/
 *
 * Webfont: MundoSansPro-Medium by Monotype
 * URL: https://www.myfonts.com/fonts/mti/mundo-sans/pro-medium/
 *
 *
 * Webfonts copyright: Copyright &amp;#x00A9; 2002-2019 Monotype Imaging Inc. All rights reserved.
 *
 * © 2021 MyFonts Inc
*/

/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/3cf1f1");

@font-face {
	font-family: 'MundoSansPro-Regular';
	src: url('fonts/webFonts/MundoSansPro-Regular/font.woff2') format('woff2'),
		url('fonts/webFonts/MundoSansPro-Regular/font.woff') format('woff');
}
@font-face {
	font-family: 'MundoSansPro-Medium';
	src: url('fonts/webFonts/MundoSansPro-Medium/font.woff2') format('woff2'),
		url('fonts/webFonts/MundoSansPro-Medium/font.woff') format('woff');
}
