* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
:root {
  font-size: 62.5%;
}
:root body {
  margin: 0;
  font-size: 1.6rem;
}

body {
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
  overflow: visible;
  font-family: 'EB Garamond', serif;
  font-style: normal;
}

.container {
  display: flex;
  justify-content: center;
  margin: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  position: fixed;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

h1 {
  font-size: 5.5rem;
  font-family: 'MundoSansPro-Regular', sans-serif;
  font-weight: normal;
  text-transform: uppercase;
  margin: 0;
  text-align: right;
  letter-spacing: 0.2rem;
  margin-right: -0.4rem;
}
h2 {
  font-size: 1.4rem;
  font-weight: 500;
  font-style: italic;
  letter-spacing: 0.3rem;
  text-align: right;
  margin-top: 10px;
}
h3 {
  font-size: 2rem;
  font-weight: 500;
  font-style: italic;
  letter-spacing: 0.5rem;
  text-align: right;
  margin: 0;
  margin-bottom: -20px;
  margin-top: 30px;
}
h4 {
  font-size: 2.2rem;
  font-weight: 500;
  font-style: italic;
  letter-spacing: 0.5rem;
  line-height: 2;
}
h5 {
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.3;
  letter-spacing: 0;
  margin: 0;
  text-align: right;
}
h6 {
  font-size: 2rem;
  font-weight: 500;
  font-style: italic;
  letter-spacing: 0.5rem;
  margin: 0;
  line-height: 1;
}
p {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 1.6;
  text-align: left;
}
span {
  font-family: 'MundoSansPro-Medium', sans-serif;
  font-style: normal;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  font-weight: 500;
}
a {
  text-decoration: none;
  color: #000;
}
a:hover {
  color: grey;
}
.fas,
.fab {
  font-size: 20px;
  margin: 10px;
}
