.aboutWFContainer {
  display: grid;
  grid-template-columns: 0.5fr 2fr 0.5fr;
  grid-template-rows: 1fr;
  gap: 0px 20px;
  align-items: center;
  width: 72vw;
  max-width: 1080px;
  margin-top: 90px;
  grid-template-areas: 'l-arrow aboutWFContainer-main r-arrow';
  max-width: 1400px;
  max-width: 1260px;
}
.aboutWFContainer-main {
  grid-area: aboutWFContainer-main;
}
.l-arrow {
  grid-area: l-arrow;
}
.r-arrow {
  grid-area: r-arrow;
  justify-self: start;
}

.arrow h2 {
  font-size: 1.2rem;
}
.arrow h2 span {
  margin: 2px;
}
.slide-active {
  display: block;
}
.slide-sliding {
  display: none;
  text-align: center;
}
.arrow:hover {
  color: grey;
}
h2 span {
  margin: 5px;
}
.images-spread,
.image-spread {
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
}
.image-spread:last-child {
  margin-bottom: 60px;
}

.images-spread.opener {
  border: none;
}

.aboutWFContainer-mobile {
  overflow-y: auto;
  width: 83vw;
  margin-top: 160px;
  padding-bottom: 140px;
}

@media (min-width: 900px) and (max-width: 1199px) {
  .aboutWFContainer {
    width: 80vw;
  }
}

@media (min-width: 761px) and (max-width: 899px) {
  .aboutWFContainer {
    width: 80vw;
    grid-gap: 10px;
  }
}
@media (min-width: 699px) and (max-width: 760px) {
  .aboutWFContainer-mobile {
    margin-top: 130px;
  }
}
@media (min-width: 540px) and (max-width: 900px) {
  .aboutWFContainer-mobile {
    width: 70vw;
  }
}
@media (min-width: 540px) and (max-width: 698px) {
  .aboutWFContainer-mobile {
    margin-top: 180px;
  }
  .aboutWFContainer-mobile.slide-active {
    margin-top: 180px;
  }
}
@media (min-width: 539px) and (max-width: 760px) {
  .aboutWFContainer-mobile {
    width: 70vw;
  }
}

@media (max-width: 400px) {
  .aboutWFContainer-mobile {
    /* padding-bottom: 160px; */
    padding-bottom: 120px;
  }
}
