@import url(//hello.myfonts.net/count/3cf1f1);
* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
:root {
  font-size: 62.5%;
}
:root body {
  margin: 0;
  font-size: 1.6rem;
}

body {
  margin: 0;
  padding: 0;
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
  overflow: visible;
  font-family: 'EB Garamond', serif;
  font-style: normal;
}

.container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  position: fixed;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

h1 {
  font-size: 5.5rem;
  font-family: 'MundoSansPro-Regular', sans-serif;
  font-weight: normal;
  text-transform: uppercase;
  margin: 0;
  text-align: right;
  letter-spacing: 0.2rem;
  margin-right: -0.4rem;
}
h2 {
  font-size: 1.4rem;
  font-weight: 500;
  font-style: italic;
  letter-spacing: 0.3rem;
  text-align: right;
  margin-top: 10px;
}
h3 {
  font-size: 2rem;
  font-weight: 500;
  font-style: italic;
  letter-spacing: 0.5rem;
  text-align: right;
  margin: 0;
  margin-bottom: -20px;
  margin-top: 30px;
}
h4 {
  font-size: 2.2rem;
  font-weight: 500;
  font-style: italic;
  letter-spacing: 0.5rem;
  line-height: 2;
}
h5 {
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.3;
  letter-spacing: 0;
  margin: 0;
  text-align: right;
}
h6 {
  font-size: 2rem;
  font-weight: 500;
  font-style: italic;
  letter-spacing: 0.5rem;
  margin: 0;
  line-height: 1;
}
p {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 1.6;
  text-align: left;
}
span {
  font-family: 'MundoSansPro-Medium', sans-serif;
  font-style: normal;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  font-weight: 500;
}
a {
  text-decoration: none;
  color: #000;
}
a:hover {
  color: grey;
}
.fas,
.fab {
  font-size: 20px;
  margin: 10px;
}

#navbarHome,
#navbar,
#navbarMexico {
  position: fixed;
  display: -webkit-flex;
  display: flex;
  width: 70vw;
  height: 120px;
  top: 50px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  z-index: 5;
}

#navbarMobile {
  position: fixed;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 0;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  text-align: right;
  height: 130px;
  background: white;
  width: 70vw;
  z-index: 5;
}

#navbarMexicoMobileInt {
  position: fixed;
  display: -webkit-flex;
  display: flex;
  width: 70vw;
  height: 90px;
  top: 50px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  z-index: 5;
}
.navbarTitle {
  margin-top: 25px;
}
.navbarMenu {
  margin-top: 20px;
  text-align: left;
}
.navbarBuy {
  font-weight: 500;
  font-style: italic;
  letter-spacing: 2px;
  font-size: 15px;
  margin-top: 10px;
}

#navbarSocial {
  position: fixed;
  display: -webkit-flex;
  display: flex;
  width: 13.5vw;
  height: 100vh;
  top: 0;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  right: 0;
  z-index: 20;
}

.social {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

#navBarToggle {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: grey;
  display: -webkit-flex;
  display: flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  z-index: 30;
}
#navBarToggle a {
  color: #fff;
}
ul.toggleNavBar {
  list-style-type: none;
  padding: 0;
}
.toggleNavBar {
  text-align: center;
  margin-bottom: 75px;
}

/* //////// MEDIA QUERIES //////////// */

@media (min-width: 1400px) {
  #navbarHome,
  #navbar,
  #navbarMexico {
    width: 66vw;
    max-width: 1260px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  #navbarHome {
    padding: 0;
  }
  #navbarSocial {
    width: 11vw;
  }
}

@media (min-width: 900px) and (max-width: 1199px) {
  #navbarSocial {
    width: auto;
    right: 3vw;
  }
  #navbarMexico {
    width: 80vw;
    padding: 0;
  }
  #navbarHome {
    width: 80vw;
  }
  #navbar {
    width: 80vw;
  }
}
@media (min-width: 841px) {
  .navbarBuy {
    margin-top: 3px;
  }
}
@media (min-width: 788px) and (max-width: 840px) {
  #navbarMexicoMobileInt {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  #navbarMexicoMobileInt::after {
    content: '';
    width: 100%;
  }
  .mobileInt:last-child {
    margin-left: auto;
    -webkit-order: 1;
            order: 1;
  }
}

@media (min-width: 699px) and (max-width: 785px) {
  #navbarMexicoMobileInt {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .navbarMexicoMobileInt {
    margin-left: auto;
  }
  .navbarMexicoMobileInt.navbarBuy.mobileInt {
    margin-top: -20px;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .navbarMenu {
    margin-top: 40px;
  }
  h1 {
    font-size: 4.5rem;
  }
  #navbarMobile {
    height: 210px;
  }
  .navbarWorldFood {
    line-height: 0.8;
  }
}

@media (min-width: 540px) and (max-width: 899px) {
  #navbarHome {
    width: 70vw;
  }
  #navbarSocial {
    width: 11vw;
  }
  #navbarMobile.navbarHomeMobileHybrid,
  #navbarMobile {
    width: 70vw;
  }
  #navbarMobile {
    height: 170px;
  }

  .navbarTitle h3 {
    margin-top: 70px;
  }
}

@media (min-width: 540px) and (max-width: 600px) {
  h3 {
    margin-top: 30px;
  }
  h1 {
    font-size: 4.5rem;
  }
}

@media (min-width: 539px) and (max-width: 760px) {
  #navbarMexicoMobile {
    width: 70vw;
  }
}

@media (max-width: 539px) {
  #navbarSocial {
    width: 100vw;
    height: 80px;
    bottom: 0;
    top: unset;
    z-index: 25;
    background: #fff;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .social {
    -webkit-flex-direction: row;
            flex-direction: row;
  }
  #navbarMobile.navbarHomeMobileHybrid {
    height: 150px;
  }
  .navbarWorldFood h1 {
    font-weight: 400;
    font-size: 5rem;
    line-height: 0.7;
  }
  #navbarMobile {
    width: 83vw;
  }
  #navbarMobile.navbarMexico {
    height: 150px;
  }
}
@media (max-width: 400px) {
  .navbarWorldFood h1 {
    font-size: 4rem;
    line-height: 0.7;
  }
  #navbarMobile.navbarHomeMobileHybrid {
    height: 140px;
  }
}

.homeContainerMobile {
  width: 83vw;
  margin-top: 140px;
  overflow-y: auto;
  padding-bottom: 90px;
}

.homeContainerMobile-main {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 0.9fr 1.1fr 1.1fr 0.9fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 10px;
  grid-template-areas:
    'ipadtl carousel-mobile carousel-mobile ipadtr' 'ipadml carousel-mobile carousel-mobile ipadmr'
    'ipadbl carousel-mobile carousel-mobile ipadbr';
  padding-bottom: 0;
}
.ipadtl {
  grid-area: ipadtl;
}
.ipadml {
  grid-area: ipadml;
}
.ipadbl {
  grid-area: ipadbl;
}
.ipadtr {
  grid-area: ipadtr;
}
.ipadmr {
  grid-area: ipadmr;
}
.ipadbr {
  grid-area: ipadbr;
}
.carousel-mobile {
  grid-area: carousel-mobile;
}

.homeContainerMobile-footer {
  margin-top: 10px;
  padding: 0;
}
.homeContainerMobile-credits h2 {
  margin: 5px 0;
  text-align: center;
  font-size: 1.2rem;
  letter-spacing: 2.5px;
}

.signUpBtn.mobile {
  margin-top: 10px;
  line-height: 0.5;
  height: 27px;
  width: 100%;
  background: #000;
  color: #fff;
  width: 100%;
  font-size: 14px;
  bottom: 0;
  font-weight: 500;
  font-family: franklin-gothic-atf, sans-serif;
  font-style: normal;
  border: none;
  padding-top: 3px;
}
.signUpBtn.mobile:hover {
  background: grey;
  color: white;
}

@media (min-width: 540px) and (max-width: 900px) {
  .homeContainerMobile {
    width: 70%;
  }

  .homeContainerMobile {
    margin-top: 205px;
  }
  .homeContainerMobile-credits {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}
@media (max-width: 539px) {
  .homeContainerMobile-main {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 0.5fr 1fr 1fr 0.5fr;
    grid-gap: 10px;
    grid-template-areas:
      'ipadtl ipadml ipadbl' 'carousel-mobile  carousel-mobile  carousel-mobile '
      'carousel-mobile  carousel-mobile  carousel-mobile' 'ipadtr ipadmr ipadbr';
  }
  .homeContainerMobile {
    padding-bottom: 200px;
  }

  .homeContainerMobile h5 {
    margin-top: 10px;
    padding-bottom: 0;
  }
}

@media (max-width: 400px) {
  .homeContainerMobile h5 {
    margin-top: 0px;
  }
  h1 {
    font-size: 4.3rem;
    line-height: 0.7;
  }
  .homeContainerMobile {
    padding-bottom: 180px;
  }
  .homeContainerMobile-h5 {
    margin-left: 12px;
  }
}

.container.home {
  -webkit-align-items: center;
          align-items: center;
}

.homeContainer-main {
  display: grid;
  grid-template-columns: 0.95fr 0.95fr 1.1fr 1.1fr 0.95fr 0.95fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 20px 20px;
  gap: 20px 20px;
  grid-template-areas: 'ltla ltra carousel-wrapper carousel-wrapper rtla copy' 'lmla lmra carousel-wrapper carousel-wrapper rmla copy' 'lbla lbra carousel-wrapper carousel-wrapper rbla copy';
  max-width: 1260px;
}

.ltla {
  grid-area: ltla;
}
.ltra {
  grid-area: ltra;
}
.lmla {
  grid-area: lmla;
}
.lmra {
  grid-area: lmra;
}
.lbla {
  grid-area: lbla;
}
.lbra {
  grid-area: lbra;
}
.rtla {
  grid-area: rtla;
}
.rmla {
  grid-area: rmla;
}
.rbla {
  grid-area: rbla;
}
.copy {
  grid-area: copy;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.carousel-wrapper {
  grid-area: carousel-wrapper;
}

.signUpBtn {
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  background: black;
  color: white;
  font-size: 14px;
  bottom: 0;
  font-weight: 500;
  font-family: franklin-gothic-atf, sans-serif;
  padding: 4px 20px;
  border: none;
  -webkit-align-self: flex-end;
          align-self: flex-end;
  text-align: center;
}
.signUpBtn:hover {
  background: grey;
  color: white;
}

.homeContainer-footer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
@media (min-width: 1400px) {
  .homeContainer-home {
    width: 66vw;
    max-width: 1260px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .homeContainer-home {
    width: 70vw;
  }
}

@media (min-width: 901px) and (max-width: 1199px) {
  .homeContainer-home {
    width: 80vw;
  }
}

.aboutWFContainer {
  display: grid;
  grid-template-columns: 0.5fr 2fr 0.5fr;
  grid-template-rows: 1fr;
  grid-gap: 0px 20px;
  gap: 0px 20px;
  -webkit-align-items: center;
          align-items: center;
  width: 72vw;
  max-width: 1080px;
  margin-top: 90px;
  grid-template-areas: 'l-arrow aboutWFContainer-main r-arrow';
  max-width: 1400px;
  max-width: 1260px;
}
.aboutWFContainer-main {
  grid-area: aboutWFContainer-main;
}
.l-arrow {
  grid-area: l-arrow;
}
.r-arrow {
  grid-area: r-arrow;
  justify-self: start;
}

.arrow h2 {
  font-size: 1.2rem;
}
.arrow h2 span {
  margin: 2px;
}
.slide-active {
  display: block;
}
.slide-sliding {
  display: none;
  text-align: center;
}
.arrow:hover {
  color: grey;
}
h2 span {
  margin: 5px;
}
.images-spread,
.image-spread {
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
}
.image-spread:last-child {
  margin-bottom: 60px;
}

.images-spread.opener {
  border: none;
}

.aboutWFContainer-mobile {
  overflow-y: auto;
  width: 83vw;
  margin-top: 160px;
  padding-bottom: 140px;
}

@media (min-width: 900px) and (max-width: 1199px) {
  .aboutWFContainer {
    width: 80vw;
  }
}

@media (min-width: 761px) and (max-width: 899px) {
  .aboutWFContainer {
    width: 80vw;
    grid-gap: 10px;
  }
}
@media (min-width: 699px) and (max-width: 760px) {
  .aboutWFContainer-mobile {
    margin-top: 130px;
  }
}
@media (min-width: 540px) and (max-width: 900px) {
  .aboutWFContainer-mobile {
    width: 70vw;
  }
}
@media (min-width: 540px) and (max-width: 698px) {
  .aboutWFContainer-mobile {
    margin-top: 180px;
  }
  .aboutWFContainer-mobile.slide-active {
    margin-top: 180px;
  }
}
@media (min-width: 539px) and (max-width: 760px) {
  .aboutWFContainer-mobile {
    width: 70vw;
  }
}

@media (max-width: 400px) {
  .aboutWFContainer-mobile {
    /* padding-bottom: 160px; */
    padding-bottom: 120px;
  }
}

.aboutJimContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  width: 70vw;
  max-width: 1080px;
  margin-top: 110px;
  height: 90vh;
}

.aboutJimContainer-left {
  max-width: 640px;
  width: 49%;
  -webkit-align-self: center;
          align-self: center;
  height: 100%;
}
.aboutJimContainer-right {
  width: 50%;
  margin-bottom: 80px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 90px;
}
.aboutJimContainer-right-copy {
  position: relative;
}
.aboutJimContainer-right-copy span {
  text-transform: uppercase;
}

.aboutJimContainer-right-copy p {
  margin: 0 5px;
  height: 44.5vh;
  overflow: auto;
  margin-right: 15px;
  margin-bottom: 0px;
}

.aboutJimContainer-right-copy p::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.aboutJimContainer-right-copy p {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.aboutJimContainer-right-books {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 20px;
}
.book {
  margin: 0 5px;
  transition: 0.5s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  position: relative;
}

.book:hover img {
  opacity: 0.5;
}
.down-arrow {
  position: absolute;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  right: 0;
  top: 95%;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 40px;
  width: 40px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  border-radius: 50%;
  background-color: black;
}
.book:hover .middle {
  opacity: 1;
}

.text {
  color: white;
  font-size: 12px;
  font-family: franklin-gothic-atf, sans-serif;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  margin-top: 12px;
}

img.aboutJim {
  height: 100%;
  object-fit: contain;
}

@media (min-width: 900px) and (max-width: 1199px) {
  .aboutJimContainer {
    width: 80vw;
  }
  img.aboutJim {
    width: 95%;
  }
}

@media (min-width: 540px) and (max-width: 899px) {
  .aboutJimContainer {
    width: 80vw;
  }
  .aboutJimContainer-left {
    width: 38%;
  }
  img.aboutJim.img {
    object-fit: contain;
    width: 95%;
  }
  .aboutJimContainer-right {
    margin-bottom: 80px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 90px;
    width: 60%;
    margin-left: 20px;
    margin-right: 25px;
  }
}

#navbarJimMobile {
  position: fixed;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 0;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  text-align: right;
  height: 130px;
  -webkit-justify-content: initial;
          justify-content: initial;
  background: white;
  width: 100vw;
  padding: 0 7.5vw 0 9vw;
  z-index: 5;
}

h6.menu {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 30px;
}

@media (min-width: 540px) and (max-width: 600px) {
  h6.menu {
    margin-top: 40px;
  }

  #navbarJimMobile {
    padding: 0 15vw;
  }
}
@media (min-width: 601px) and (max-width: 900px) {
  h6.menu {
    margin-top: 40px;
  }

  .mobile-container {
    position: fixed;
  }
}

.container-jim {
  width: 83vw;
  margin-top: 120px;
  overflow-y: auto;
  display: grid;
  grid-template-columns: 40% 25% 25%;
  grid-template-rows: 2fr 1fr 1fr 1fr;
  grid-gap: 5px 10px;
  gap: 5px 10px;
  grid-auto-flow: row;
  grid-template-areas:
    'jim-foto mexico paris'
    'jim-foto comfort cradle'
    'jim-foto fork neurosis'
    'jim-copy jim-copy jim-copy';
}

.jim-copy {
  grid-area: jim-copy;
}

.jim-foto {
  grid-area: jim-foto;
}

.mexico {
  grid-area: mexico;
}

.paris {
  grid-area: paris;
}

.comfort {
  grid-area: comfort;
}

.cradle {
  grid-area: cradle;
}

.fork {
  grid-area: fork;
}

.neurosis {
  grid-area: neurosis;
}

.booker {
  transition: 0.5s ease;
  position: relative;
  width: 100%;
}

.booker:hover img {
  opacity: 0.5;
}
.booker:hover .middle {
  opacity: 1;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 40px;
  width: 40px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  border-radius: 50%;
  background-color: black;
}

.text {
  color: white;
  font-size: 12px;
  font-family: franklin-gothic-atf, sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 12px;
}

img.jim-img {
  height: 100%;
  object-fit: contain;
  margin-top: 10px;
}
span {
  text-transform: uppercase;
}

p::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
p.grid-copy {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

p.grid-copy {
  margin: 0;
  padding-bottom: 210px;
}

/* @media (max-width: 400px) {
  .jim-container-mobile.minid {
    padding-bottom: 140px;
  }
} */

#newsletterContainer {
  max-width: 900px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  width: 37vw;
  margin-right: 7vw;
  margin-top: 100px;
  overflow-y: scroll;
  margin-bottom: 40px;
}

#newsletterContainer p {
  margin-left: 20%;
  font-size: 18px;
  line-height: 1.5;
  text-align: right;
  color: black;
  margin-right: 0;
  margin-bottom: 20px;
}
.forms {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  max-width: 900px;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  margin-bottom: 30px;
}
.chimped {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.newsletterContainer-intro {
  position: relative;
  z-index: 3;
}
.newsletterContainer-intro p {
  margin-top: 30px;
}

form {
  width: 100%;
}
form button {
  background: black;
  color: white;
  text-transform: uppercase;
  padding: 10px;
  border: none;
  border-radius: 5%;
}
form button:hover {
  background: grey;
}

input {
  height: 50px;
  width: 80%;
  max-width: 900px;
  margin-bottom: 30px;
  padding: 10px;
}
input:focus {
  outline: none;
}
input:hover {
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.video {
  width: 80%;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  margin-left: 20%;
  position: relative;
  cursor: pointer;
  margin-bottom: 30px;
}
.vid-button {
  position: absolute;
  z-index: 5;
  top: 60%;
  left: 40%;
  color: white;
  font-size: 1rem;
}
.fa-youtube,
.fa-5x {
  font-size: 3em;
}

@media (min-width: 1400px) {
  .video {
    max-width: 485px;
    margin-left: 14%;
  }
}

@media (min-height: 800px) and (max-height: 1280px) {
  #newsletterContainer {
    margin-top: 120px;
  }
  input,
  p {
    margin-bottom: 20px;
  }
}
@media (min-width: 1150px) {
  #newsletterContainer {
    width: 45vw;
    margin-right: 8.5vw;
    margin-top: 100px;
  }
  #newsletterContainer p {
    margin-left: 20%;
  }
}
@media (min-width: 1075px) and (max-width: 1149px) {
  #newsletterContainer {
    width: 45vw;
    margin-right: 8vw;
  }
  #newsletterContainer p {
    margin-left: 15%;
  }
}
@media (min-width: 700px) and (max-width: 1074px) {
  #newsletterContainer {
    width: 55vw;
    margin-right: 10vw;
    margin-top: 100px;
    margin-bottom: 90px;
    position: fixed;
  }
  .newsletterContainer-intro,
  .video {
    margin-right: 5px;
  }
  #newsletterContainer p {
    margin-right: 0px;
  }
}
@media (max-width: 800px) {
  #newsletterContainer {
    margin-top: 130px;
  }
}

@media (min-width: 540px) and (max-width: 699px) {
  #newsletterContainer {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    margin-right: 15vw;
    width: 90vw;
    margin-top: 140px;
  }
  #newsletterContainer p {
    margin-left: 20%;
    font-size: 1.9rem;
    line-height: 1.2;
    overflow: unset;
  }
}

@media (max-width: 539px) {
  input {
    width: 90%;
    margin-bottom: 20px;
  }
  #newsletterContainer {
    width: 100vw;
    padding-bottom: 180px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-bottom: 0;
  }
  #newsletterContainer p {
    margin-left: 10%;
    margin-bottom: 20px;
    line-height: 1.2;
  }

  .video {
    width: 100%;
  }
  .video img {
    width: 90%;
  }
}
@media (max-width: 400px) {
  #newsletterContainer {
    padding-bottom: 130px;
  }
}

/**
 * @license
 * MyFonts Webfont Build ID 3994097, 2021-01-06T14:29:25-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: MundoSansPro-Regular by Monotype
 * URL: https://www.myfonts.com/fonts/mti/mundo-sans/pro-regular/
 *
 * Webfont: MundoSansPro-Medium by Monotype
 * URL: https://www.myfonts.com/fonts/mti/mundo-sans/pro-medium/
 *
 *
 * Webfonts copyright: Copyright &amp;#x00A9; 2002-2019 Monotype Imaging Inc. All rights reserved.
 *
 * © 2021 MyFonts Inc
*/

/* @import must be at top of file, otherwise CSS will not work */

@font-face {
	font-family: 'MundoSansPro-Regular';
	src: url(/static/media/font.ae5332fc.woff2) format('woff2'),
		url(/static/media/font.a2ba6166.woff) format('woff');
}
@font-face {
	font-family: 'MundoSansPro-Medium';
	src: url(/static/media/font.62435c3d.woff2) format('woff2'),
		url(/static/media/font.e40b8e82.woff) format('woff');
}

